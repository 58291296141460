<template>
  <domain-items-list
    modal-id="tags-modal"
    modal-title="Offer Tag"
    :api="{
      getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    }"
    :have-description="false"
    :have-category="false"
    :item.sync="item"
  />
</template>
<script>
import DomainItemsList from '@/common/components/GeneralSettings/domain-items/DomainItemsList.vue'
import domainItemApi from '@/common/compositions/DomainItems/domainItemApi'

export default {
  name: 'Tags',
  components: {
    DomainItemsList,
  },
  provide() {
    return {
      resource: 'global-OfferTagController',
    }
  },
  setup() {
    const {
      item, getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    } = domainItemApi('activities', '/internalops/offer/tag')

    return {
      item, getItemsRequest, addItemRequest, updateItemRequest, resetItem, toggleStatus,
    }
  },
}
</script>
<style lang="">

</style>
